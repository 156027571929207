@import index.sass

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
// a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
// ul,
// li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video
  margin: 0
  padding: 0
  border: 0
  font-size: 100%
  font: inherit
  // vertical-align: baseline

/* HTML5 display-role reset for older browsers
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section
  display: block

html
  height: 100dvh

body
  position: relative
  top: 0
  bottom: 0
  right: 0
  left: 0
  height: 100%
  font-family: 'Montserrat', sans-serif !important
  font-weight: 400 !important
  // font-family: 'Helvetica Neue' !important
  // font-family: 'Open Sans', sans-serif
  // font-family: 'Open Sans', sans-serif
  font-size: 14px !important
  line-height: 1
  color: $font_color !important
  background-color: $backgroundColor !important
  scroll-behavior: smooth

::-webkit-scrollbar 
  width: 6px
  height: 6px

::-webkit-scrollbar-track 
  border-radius: 10px
  background: rgba(0,0,0,0.1)

::-webkit-scrollbar-thumb
  border-radius: 10px
  background: rgba(0,0,0,0.2)

::-webkit-scrollbar-thumb:hover
  background: rgba(0,0,0,0.4)

::-webkit-scrollbar-thumb:active
  background: #a8a8a8e6


ol,
ul
  list-style: none

blockquote,
q
  quotes: none

blockquote:before,
blockquote:after,
q:before,
q:after
  content: ''
  content: none

table
  border-collapse: collapse
  border-spacing: 0

button
  padding: 0
  margin: 0
  font: inherit
  color: inherit
  background-color: transparent
  border: none
  border-radius: none
  background: none
  border: none
  cursor: pointer
  border-bottom: 2px solid transparent
  border-radius: 2px !important

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace

input
  border-radius: 6px !important
  height: 30px !important

.clearfix::after
  content: ""
  display: table
  clear: both

#root
  display: grid
  grid-template-rows: 1fr
  min-height: 100%
  max-height: 100%
  position: relative
  top: 0
  bottom: 0
  right: 0
  left: 0
  overflow: hidden

.spinner-border
  margin: 20% auto

// .nopadding
//   padding-left: 5px !important
//   padding-right: 5px !important

input:disabled, input[readonly], textarea:disabled, textarea[readonly], select:disabled, select[readonly]
  background: none !important
  // border: none !important
  // font-weight: bold !important
  outline: none
  cursor: text

div, ul, li
  // border-radius: 4px
h1
  font-size: 1.4rem
  font-family: 'Montserrat'
  font-weight: 900
h2
  font-weight: 700
  font-size: 1.3rem
h3
  font-weight: 500
  font-size: 1.2rem
h4
  font-weight: 500
  font-size: 1rem
h5
  font-weight: 500
  font-size: 0.8rem
p
  font-weight: 400
  font-size: 13px

// Не валидные данные в инпуте
input, select
  &.not-valid
    border: 1px solid $third_color !important

.css-79elbk
  .css-17n5zh
    height: 30px
    min-height: 30px
    .css-154qh9p
      align-content: center


*
  &[readonly]
    color: #333 !important
    opacity: 1 !important
    border: 1px solid #fff !important
    border-radius: 0px !important
    border-bottom: 1px solid #dadada !important

// .ui.disabled.input, .ui.input:not(.disabled) input[disabled] {
//     opacity: 1
//     border-bottom: 1px solid #555
// }

.app__preloader
  display: grid !important
  grid-template-rows: max-content max-content
  width: 100%
  height: 100%
  justify-content: center
  align-content: center
  background-color: $backgroundColor_dark
  .desc
    position: relative
    color: #edebe9
    // font-family: 'Mulish', sans-serif !important

select
  background: #fff
  border-radius: 4px
  border: 1px solid rgba(34, 36, 38, 0.15)
  width: 30px
.css-ky7590
  -webkit-box-shadow: 0px 0px 8px 1px rgba(34, 60, 80, 0.2) 
  -moz-box-shadow: 0px 0px 8px 1px rgba(34, 60, 80, 0.2) 
  box-shadow: 0px 0px 8px 1px rgba(34, 60, 80, 0.2) 
  border-radius: 4px

.chakra-input, .select
  border-radius: 8px !important
  height: 42px !important
  color: #252733 !important
  border-color: #9da0b1 !important
  &:hover
    border-color: #cbd5e0 !important
  &:focus-visible
    border-color: #FE8A00 !important
    box-shadow: none !important
.select-field
  border-radius: 8px !important
  border-color: #9da0b1 !important
  transition: all 200ms
  &:hover
    border-color: #cbd5e0 !important
  &:focus-visible
    border-color: #FE8A00 !important
    box-shadow: none !important
.react-datepicker__day--selected
  background-color: #FE8A00 !important
.react-datepicker__time-list-item--selected
  background-color: #FE8A00 !important
.workspace
  display: grid
  // grid-template-rows: 119px calc(100vh - 119px - 15px)
  grid-template-rows: minmax(120px, .5fr) 1fr
  // padding-right: 30px
  width: 100%
  height: 100%
  grid-gap: 15px
  background: -webkit-linear-gradient(180deg, rgb(247, 248, 252) 43%, rgb(255, 238, 218))
  background: -moz-linear-gradient(180deg, rgb(247, 248, 252) 43%, rgb(255, 238, 218))
  background: linear-gradient(180deg, rgb(247, 248, 252) 43%, rgb(255, 238, 218))

.workspace-mobile
  display: grid
  grid-template-rows: minmax(120px, .3fr) 1fr
  width: 100%
  height: 100%
  grid-gap: 15px
  background: -webkit-linear-gradient(180deg, rgb(247, 248, 252) 43%, rgb(255, 238, 218))
  background: -moz-linear-gradient(180deg, rgb(247, 248, 252) 43%, rgb(255, 238, 218))
  background: linear-gradient(180deg, rgb(247, 248, 252) 43%, rgb(255, 238, 218))

.css-1lavg0u:focus-visible
  box-shadow: none !important
.chakra-table__container
  border-radius: 8px 8px 0 0
  position: relative
  .chakra-table
    thead
      position: sticky
      top: 0
      background-color: #ffffff
      border-bottom: 1px #edf2f7 solid
      box-shadow: 0px 4px 10px -8px rgb(34 60 80 / 20%)
.reset-icon
  top: 32px
  right: 30px
  position: absolute
  padding: 0 !important
  background: none !important
  &:hover
    cursor: pointer
