.application-search
  padding-top: 20px
  display: grid
  justify-items: center
  justify-content: center
  // align-content: center
  width: 100%
  height: 100%
  grid-gap: 20px
  padding-right: 42px
  grid-template-rows: max-content 1fr
  grid-template-columns: max-content minmax(200px, 600px)
  grid-template-areas: 'a b' 'c d'
  // padding: max(20px, 10vh)
  // padding-bottom: max(20px, 10vh)
  // padding-left: max(100px, 10vw)
  // padding-right: max(100px, 10vw)
  //   font-size: 18px
  @media screen and (min-width: 800px)
    font-size: 20px
  @media screen and (max-width: 799px) 
    font-size: 18px
  .search-field
    grid-area: b
    width: 100%
    max-width: 600px
    input
      background-color: #FFFFFF
  .application-card
    grid-area: d
    overflow: auto
    @media screen and (min-width: 800px)
      grid-template-columns: repeat(auto-fit, minmax(500px, 1fr))
      padding: 20px
    @media screen and (max-width: 799px)
      padding: 10px
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr))
    display: grid
    grid-template-rows: auto
    position: relative
    width: 100%
    max-width: 600px
    border: 1px solid #9da0b1
    height: max-content
    max-height: 90%
    justify-items: center
    // align-items: center
    background: #FFFFFF
    border-radius: 8px
    -webkit-box-shadow: 0px 0px 2px 1px rgba(34, 60, 80, 0.1)
    -moz-box-shadow: 0px 0px 2px 1px rgba(34, 60, 80, 0.1)
    box-shadow: 0px 0px 2px 1px rgba(34, 60, 80, 0.1)
    color: black
    .name,.pass,.create-date,.update-date, .status-text
      width: 100%
    .status-text
      display: flex
    .status
      position: absolute
      right: 20px
      top: 20px
      &:hover
        cursor: help

.workspace-mobile
  .application-search
    padding: 0 !important
    grid-template-areas: 'b' 'd' !important    
    grid-template-columns: 1fr !important
    font-size: 16px
