
// Цветовая гамма
$accent_color: #18cf9f
$second_color: #27ae60
$main_dark_color: #008452
$third_color: #E81123
$backgroundColor: #f4f4f4
$backgroundColor_light: #F7F8FC 
$backgroundColor_dark: #363740
$sidebar_color: $accent_color
$info_sidebar_color: #ffffff
$additional_color: #16a085
$font_color: #9FA2B4
// $backgroundColor: #f5f5f5
// $backgroundColor: #f2f3f7
// $accent_color: #2980b9
// $accent_color: #498cdd
// $accent_color: #2980b9
// $accent_hover_color: #3498db
// $accent_color: #3498db
// $accent_hover_color: #2980b9

// $second_color: #2ecc71
$second_dark_color: #27ae60

// $second_color: #1abc9c
// $second_dark_color: #16a085

// $main_dark_color: #34495e

// $third_color: #e74c3c
$third_dark_color: #c0392b

// Цвет шрифта
$light_font: #ecf0f1
$dark_font: rgba(0,0,0,.87)
$main_font: rgba(0,0,0,.8)
$active_light_color: rgba(255,255,255,.2)

$lightBack: lighten(#fffce2, 2%)

// Тени
$shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2)
$shadow_hover: 0px 2px 4px 1px rgba(0, 0, 0, 0.3)
$box_shadow: 0 1px 5px 1px #e1e1e1
// Отступы
$gap: 0.5em

// Типографика

$mainSize: 14px
$border: 1px solid rgba(34, 36, 38, 0.15)
$borderRadius: 4px
