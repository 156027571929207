.workspace-container
  display: grid
  justify-content: center
  grid-template-columns: 1fr
  // position: absolute
  // height: 91vh
  height: 100%
  // padding: 40px 0 0 0
  // bottom: 0px
  // top: 0
  // left: 0
  // right: 0
  overflow: hidden
  padding: 0 25px
  // padding-bottom: 30px
