@import "_mixins.sass"

@font-face
  font-family: 'Mulish'
  src: local('Mulish'), local('Mulish'), url('../fonts/Mulish/Mulish.ttf') format('truetype')
  font-weight: 400
  font-style: normal

@font-face
  font-family: 'Montserrat'
  src: local('Montserrat'), local('Montserrat'), url('../fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype')
  font-weight: 400
  font-style: normal

// @font-face
//   font-family: 'Mulish'
//   src: local('Mulish Italic'), local('Mulish-Italic'), url('../fonts/Mulish/Mulish-Italic.ttf') format('truetype')
//   font-weight: 300
//   font-style: normal
