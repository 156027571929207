.upload-container
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  height: calc(100% - 20px)
  width: 100%
  outline: 1px dashed #9da0b1
  padding: 10px
  // outline-offset: -12px
  background-color: #dddddd56
  color: #545e6c
  border-radius: 8px
  transition: outline .1s
  position: relative
  margin-bottom: 17px
  label
    font-weight: bold
    padding-right: 5px
    &:hover
      cursor: pointer
      text-decoration: underline
    .focus
      outline: 1px solid #0078d7
      outline: -webkit-focus-ring-color auto 5px
    .dragover
      background-color: #fafafa
      outline-offset: -17px
  div
    position: relative
    z-index: 10
    text-transform: none
  input[type=file]
    width: 0.1px
    height: 0.1px
    opacity: 0
    position: absolute
    z-index: -10
  .file-formats
    text-transform: none
    position: absolute
    bottom: -17px
    font-size: 12px
  .delete-icon
    position: absolute
    top: 4px
    right: 4px
    cursor: pointer

.highlight
  outline-color: rgb(255, 160, 43)
