.header
  width: 100%
  display: grid
  grid-template-columns: 1fr
  grid-template-rows: 99px
  align-items: center
  grid-gap: 10px
  margin-top: 20px
  position: relative
  height: max-content
  justify-items: end
  align-self: end
  .title
    display: flex
    line-height: 24px
    font-size: 24px
    font-weight: 700
    color: #252733
  .logo
    height: 100%
    width: 100%
    display: grid
    align-content: end
    justify-items: center
    // position: absolute
    grid-gap: 5px
    font-size: 24px
    font-weight: 700
    color: #FE8A00
    letter-spacing: 0.4px

  .back-icon
    height: 100%
    width: 100%
    z-index: 100
    display: grid
    align-content: end
    grid-template-rows: 1fr
    justify-items: start
    position: absolute
    background: none !important
    svg
      width: 5vh
      height: 5vh
      min-height: 34px !important
      min-width: 34px !important
    &:hover
      cursor: pointer
  .userMenu
    top: 25px
    right: 25px
    position: fixed 
    display: grid
    grid-template-columns: max-content
    grid-gap: 20px
    align-items: center
    color: #9FA2B4
    z-index: 20
    .flags
      display: grid
      grid-gap: 5px
      grid-template-columns: max-content max-content
      img
        width: 28px
        opacity: 0.5
        &:hover
          cursor: pointer
      .active
        opacity: 1
    .chakra-menu__menu-button
      color: #252733
      max-width: 200px
      span
        display: flex
        align-items: center
        .root-icon
          margin-right: 5px
      svg
        // width: 16px
    .menu-list
      -webkit-box-shadow: 0px 0px 8px 1px rgba(34, 60, 80, 0.2) 
      -moz-box-shadow: 0px 0px 8px 1px rgba(34, 60, 80, 0.2) 
      box-shadow: 0px 0px 8px 1px rgba(34, 60, 80, 0.2)
      .chakra-menu__group__title
        margin-bottom: 0
      .chakra-menu__icon-wrapper
        width: 20px
        height: 20px
        .chakra-icon
          width: 100%
          height: 100%

.workspace-mobile
  .header
    position: relative
    .userMenu
      padding-right: 10px