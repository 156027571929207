.application-create__content
  // display: grid
  // grid-template-rows: 1fr 1fr max-content
  // padding: 40px
  // grid-gap: 40px
  // overflow: overlay
  // @-moz-document url-prefix() 
  //   overflow: auto
  // position: relative
  &-body
    display: grid
    height: 100%
    grid-gap: 20px
    overflow: overlay
    @-moz-document url-prefix() 
      overflow: auto
    grid-template-rows: 1fr .5fr
    .field
      display: grid
      grid-gap: 5px
      display: grid
      grid-template-rows: max-content 1fr
      color: #9FA2B4
      text-transform: uppercase
      position: relative
      .title
        display: grid
        grid-template-columns: max-content max-content
        height: max-content
        font-weight: 600
    .fields
      display: grid
      grid-template-rows: repeat(3, max-content)
      grid-gap: 30px
      .field
        display: grid
        grid-gap: 5px
        display: grid
        grid-template-rows: repeat(2, max-content)
        color: #9FA2B4
        text-transform: uppercase
        position: relative
        margin-bottom: 0
        .title
          display: grid
          grid-template-columns: max-content max-content
          height: max-content
          font-weight: 600
        &:last-child
          .title
            grid-template-columns: 1fr

      // height: 100%
      // display: grid
      // // min-height: 250px
      // width: 100%
      // // max-width: 600px
      // // max-width: 30vw
      // // margin-top: 15px
      // // margin-bottom: 15px
      // // padding: 5px
      // justify-self: center
      // background-color: #F7F8FC
      // justify-content: center
      // align-items: center
      // font-size: 24px
      // font-weight: 700
      // color: #252733
      // text-align: center
      // line-height: 30.12px
      // cursor: pointer
      // overflow: hidden
      // position: relative
      // -webkit-box-shadow: 0px 0px 10px 3px rgba(34, 60, 80, 0.2) inset
      // -moz-box-shadow: 0px 0px 10px 3px rgba(34, 60, 80, 0.2) inset
      // box-shadow: 0px 0px 10px 3px rgba(34, 60, 80, 0.2) inset
      .background-hover
        z-index: 1
        position: absolute
        width: 100%
        height: 100%
        display: flex
        align-items: center
        color: white
        background: #00000041
        transition: all 0.5s
        // &:hover
        //   background: #0000004f
      // img
        // height: 100%
        // width: auto
        // display: block
        // vertical-align: top
        // height: 200px
      .text
        @media screen and (max-width: 1299px)
          margin: 0
        margin: 130px
    .document
      min-height: 42px
      display: grid
      width: 100%
      font-size: 16px
      cursor: pointer
      justify-content: center
      align-items: center
      position: relative
      border-bottom: solid 1px #9FA2B4
      grid-gap: 10px
      grid-template-columns: max-content 1fr max-content
      .text
        text-transform: none
        height: max-content
        line-height: 16px
        color: #252733
    .file
      display: none
      position: absolute
  &-footer
    display: grid
    justify-content: center
    align-self: end
    width: 100%
    grid-gap: 20px
    // height: 100% !important
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr))
    .chakra-button
      width: 100%
      border-radius: 5px !important
      border: none
      margin: 0
      &:first-child
        background-color: #FE8A00 !important
        color: #ffffff
        &:hover
          background-color: #ee8300 !important
        &:active
          background-color: #e07b00 !important
      &:last-child
        border: 0.5px solid #9FA2B4
        background: #FFFEFE
        color: #4B506D
        &:hover
          background-color: #f1f1f1 !important
        &:active
          background-color: #e4e4e4 !important

// .workspace-mobile
//   .application-create__form
//     border-radius: 0
//     width: 100%
//     height: 100%
//     padding: 4vh
//     @media screen and (orientation: portrait)
//       grid-template-columns: 1fr
//       grid-template-rows: 1fr max-content
//       grid-template-areas: 'b b' 'a a'
//       .content
//         grid-area: a
//         grid-template-rows: repeat(2, max-content)
//       .image
//         grid-area: b

.drawer-trigger__btn-tooltip
  // font-size: 20px !important
.drawer-trigger__btn-mob
  right: 25px
  bottom: 25px
  width: 60px
  // min-width: 25px
  height: 60px !important
  position: absolute !important
  border-radius: 9999px !important
  z-index: 100
  // background: rgb(255, 174, 82) !important
  background: rgb(255, 160, 43) !important
  transition: background .6s, box-shadow .6s, border .6s, scale .1s !important
  box-shadow: 0px 0px 20px 2px rgba(34, 60, 80, 0.2)
  border: 2px solid rgb(255, 160, 43)
  &:hover
    // width: 80px
    // height: 80px !important
    border: 2px solid rgb(255, 139, 0)
    background: rgb(255, 139, 0) !important
    box-shadow: 0px 0px 30px 4px rgba(34, 60, 80, 0.3)
  &:active
    scale: 0.9
  svg
    width: 40%
    height: 40%

.drawer-trigger__btn
  grid-area: a
  background: rgb(255, 160, 43) !important
  transition: background .6s, box-shadow .6s, border .6s, scale .1s !important
  border: 2px solid rgb(255, 160, 43)
  border-radius: 8px !important
  height: 42px !important
  min-width: 42px !important
  box-shadow: 0px 0px 20px 2px rgba(34, 60, 80, 0.2)
  &:hover
    border: 2px solid rgb(255, 139, 0)
    background: rgb(255, 139, 0) !important
    box-shadow: 0px 0px 30px 4px rgba(34, 60, 80, 0.3)

