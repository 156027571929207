.spinner-container
  display: grid !important
  width: 100%
  height: 100%
  justify-content: center
  align-content: center
  position: absolute
  top: 0
  left: 0
  background-color: #ffffff47
  backdrop-filter: blur(1px)
  transition: opacity .2s
  .app__loader
    display: grid !important
    grid-template-rows: max-content max-content
    width: 100%
    height: 100%
    justify-items: center
    align-content: center
    .desc
      position: relative
      color: #9b9b9b
    .chakra-progress__track
      stroke: #e5e4e4


.spinner-border
  margin: 0 auto